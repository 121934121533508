<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div class="bottom_space flex justify-between p-2">
        <div class="pt-1">
          <div class="pb-2">
            <button class="mr-3 text-sm border  hover:bg-info py-2.5 text-text1 rounded-md list_btn" :class="statusVal ? 'bg-info border-info' : 'bg-white border-gray-200'" @click="statusChip('active')">Active</button>
            <button class="mr-3 text-sm border  hover:bg-info py-2.5  text-text1 rounded-md list_btn" :class="!statusVal ? 'bg-info border-info' : 'bg-white border-gray-200'" @click="statusChip('inactive')">Inactive</button>
          </div>
          </div>
          <div v-if="IsSuperAdmin">
            <button class="list_top_card_btn bg-primary cta-text text-white py-3 px-5 rounded-3xl" @click="addUserPopup('0')">Add Field</button>
          </div>
      </div>
      <div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder " v-if="!mobileView">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
          <div class="cell_col flex items-center">
            <span class="cursor-pointer">{{titleObj[0].title}} </span>
          </div>
          <div class="cell_col flex items-center">
            <span class="cursor-pointer" >{{titleObj[1].title}}</span>
          </div>
          <div class="desc_col flex items-center">
            <span class="cursor-pointer" >{{titleObj[2].title}}</span>
          </div>
        </div>
        <div v-for="(data, index) in fieldList" :key="index" class="min-w-min group   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center cursor-pointer hover:bg-gray-50">
          <div class="cell_col">
            <span class="heading-4 text-text2" v-if="data.fieldName !== ''">{{data.fieldName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col">
            <span class="heading-4 text-text2" v-if="data.fieldDataType !== ''">{{data.fieldDataType}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="desc_col">
            <span class="heading-4 text-text2" v-if="data.filedDescription !== ''">{{data.filedDescription}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col">
            <p class="invisible group-hover:visible ">
              <span class="px-1 pr-3 cursor-pointer" @click="addUserPopup(data.fieldMasterId)">
                <i class="fas fa-edit text-primary h-5 w-5" ></i>
              </span>
              <!-- <span class="px-1 pr-3 cursor-pointer" @click="removeField(data.fieldMasterId)">
                <i class="fas fa-trash-alt text-error h-5 w-5" ></i>
              </span> -->
            </p>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="fieldList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showDetailPopup" class="popup_overlay">
    <div class="bg-white rounded-sm custom_dialog" style="width:890px;max-height:750px;">
      <div class="cust_card">
        <div class=" rounded px-3 py-2 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
          <div>
            <p class="">{{this.masterID > 0 ? 'Edit Field' : 'Add Field'}}</p>
          </div>
          <div>
            <div>
              <button class="font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="showDetailPopup = false">Discard</button>
              <button class="font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveField()">Save</button>
            </div> 
          </div>  
        </div>
        <div class="pb-4 p-2" style="max-height: 700px!important;overflow-y: auto;">
          <div class="grid grid-cols-12">
            <div class="box col-span-12 p-2">
              <p class="label_css">Field Name</p>
              <input
              :class="fNameErr ? 'border border-error' : 'focus:border-gray-800 border border-gray-500'"
              class="outline-none text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
              placeholder="Field Name"
              v-model="sendObj.fieldName"
              >
              <p class="text-error" v-if="fNameErr">Field name is Required</p>
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="box col-span-12 p-2">
              <p class="label_css">Field DataType</p>
              <input
              :class="fDataTErr ? 'border border-error' : 'focus:border-gray-800 border border-gray-500'"
              class="outline-none   text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
              placeholder="Field DataType"
              v-model="sendObj.fieldDataType"
              >
              <p class="text-error" v-if="fDataTErr">Field Data Type is Required</p>
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="box col-span-12 p-2">
              <p class="label_css">Filed Description</p>
              <input
              :class="fDescErr ? 'border border-error' : 'focus:border-gray-800 border border-gray-500'"
              class="outline-none text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
              placeholder="Filed Description"
              v-model="sendObj.filedDescription"
              >
              <p class="text-error" v-if="fDescErr">Field Description is Required</p>
            </div>
          </div>
          <div class="pl-2 grid-cols-12 flex items-center">
            <input type="checkbox" class="h-5 w-5 cursor-pointer" v-model="sendObj.isActive">
            <p class=" text-text1 pl-2 heading-5">Field is {{sendObj.isActive ? 'Active' : 'InActive'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import HRMAPI from '@/View/CMS/api/HRM.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  data () {
    return {
      IsSuperAdmin: false,
      fNameErr: false,
      fDescErr: false,
      fDataTErr: false,
      masterID: 0,
      sendObj: {
        fieldMasterId: 0,
        fieldName: '',
        fieldDataType: '',
        filedDescription: '',
        isActive: true
      },
      showDetailPopup: false,
      mobileView: false,
      statusVal: true,
      showCustomerAddDialog: false,
      titleObj: [
        {title: 'Field Name'},
        {title: 'Field Data Type'},
        {title: 'Field Description'},
    ],
      isActive: true,
      fieldList: []
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.IsSuperAdmin = Isadmin.isSuperAdmin
    this.resizeWindowHandler();
    document.title = 'Field List'
    this.getFieldList()
    this.$root.$on("closeDialog", (data) => {
      if (data === false) {
        this.getFieldList()
      }
    });
  },
  watch: {
    sendObj: {
      handler () {
        if (this.sendObj.fieldName !== '') {
        this.fNameErr = false
        }
        if (this.sendObj.filedDescription !== '') {
          this.fDescErr = false
        }
        if (this.sendObj.fieldDataType !== '') {
          this.fDataTErr = false
        }
      },
      deep: true
    }
  },
  methods: {
    saveField () {
      if (this.sendObj.fieldName === '') {
        this.fNameErr = true
      }
      if (this.sendObj.filedDescription === '') {
        this.fDescErr = true
      }
      if (this.sendObj.fieldDataType === '') {
        this.fDataTErr = true
      }
      if (!this.fNameErr && !this.fDescErr && !this.fDataTErr) {
        this.sendObj.fieldMasterId = JSON.parse(this.masterID)
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        HRMAPI.saveFieldName(
          this.sendObj,
          response => {
            this.showDetailPopup = false
            this.getFieldList()
            this.sendObj.fieldDataType = ''
            this.sendObj.fieldMasterId = 0
            this.sendObj.filedDescription = ''
            this.sendObj.fieldName = ''
            this.sendObj.isActive = true
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    removeField (Id) {
      this.sendObj.fieldMasterId = JSON.parse(this.masterID)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.removeFieldName(
        Id,
        response => {
          this.getFieldList()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    addUserPopup (ID) {
      this.masterID = ID
      console.log(this.masterID)
      if (this.masterID > 0) {
        this.getFieldDetail(ID)
      } else {
        this.showDetailPopup = true
      }
      // this.showDetailPopup = true
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    statusChip (val) {
      console.log(val)
      if (val === 'active') {
        this.statusVal = true
        this.isActive = true
      } else if (val === 'inactive') {
        this.statusVal = false
        this.isActive = false
      }
      this.getFieldList()
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getFieldList()
    },
    getFieldDetail (Id) {
      console.log('ID', Id)
      this.fieldList.forEach(ele => {
        console.log('ele', ele)
        if (ele.fieldMasterId === Id) {
          this.sendObj.fieldDataType = ele.fieldDataType
          this.sendObj.fieldMasterId = ele.fieldMasterId
          this.sendObj.fieldName = ele.fieldName
          this.sendObj.filedDescription = ele.filedDescription
          this.sendObj.isActive = ele.isActive
          this.showDetailPopup = true
        }
      })
    },
    getFieldList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.inputFieldListing(
        this.isActive,
        response => {
          console.log("response", response.Data)
          this.fieldList = response.Data === null ? [] : response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    this.$root.$off('closeDialog')
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.desc_col {
  min-width: 600px !important;
  width: 600px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 300px !important;
  width: 300px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
</style>
